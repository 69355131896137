
import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import BaseTKInfoModal from "@monorepo/uikit/src/components/BaseTKInfoModal/BaseTKInfoModal.vue";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKLogView/viewTitle";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { fullModalElements as modalElements } from "@monorepo/utils/src/variables/projectsData/BaseTKInfoModal/modalElements";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "TKLogInfoModal",
  extends: BaseTKInfoModal,
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      viewUniqKey,
    };
  },
  computed: {
    ...mapGetters("TKLogView", ["data", "openedId", "test"]),
  },
  methods: {
    ...mapActions("TKLogView", ["getTkElement"]),
    ...mapActions("TKLogView", ["changeOpenedId"]),
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "TKLogView", Sections.TK_LIST, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "TKLogView");
    const { resultModalElements, moveByAuthorities, formResultLinks } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      resultModalElements,
      addQueryOpenedId,
      moveByAuthorities,
      formResultLinks,
    };
  },
});
