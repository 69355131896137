import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";

export const filtersElements = [
  {
    value: fields.TK_ID,
    title: "Идентификатор ТК",
  },
  {
    value: fields.TK_DATE,
    title: "Период",
  },
  {
    value: fields.TK_STATUS,
    title: "Статус ТК",
  },
  {
    value: fields.TK_DIRECTION,
    title: "Направление ТК",
  },
  {
    value: fields.OIK_CODE,
    title: "Код ОИК",
  },
  {
    value: fields.TK_OIK_ID,
    title: "Идентификатор ТК ОИК",
  },
  {
    value: fields.TK_TYPE,
    title: "Тип ТК",
  },
];
