export interface IAutocompleteElement {
  name: string;
  code: string;
  number?: string;
  version?: string;
  isSearchNumber?: boolean;
  isSearchCode: boolean;
  titleValue?: string;
}

export const autocompleteElement: IAutocompleteElement = {
  name: "name",
  code: "code",
  number: "number",
  isSearchCode: false,
  isSearchNumber: false,
  titleValue: "titleValue",
};
