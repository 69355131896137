type headerMin = { width: number; defaultWidth: number; id: string; value: string; isLink: boolean; linkRights: string[] };

export const getFormattedHeaders = (headers: headerMin[], visibleHeaders: headerMin[], difference: number): headerMin[] => {
  visibleHeaders = visibleHeaders.filter((item) => item.value !== "checkbox");
  return headers.map((item: headerMin) => {
    const el = visibleHeaders.find((header: headerMin) => header.id === item?.id);
    if (el) {
      item.width = item?.defaultWidth + difference / visibleHeaders.length;
      item.width = item.width < 37 ? 37 : item.width;
    }
    return item;
  });
};
