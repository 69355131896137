import { convertOikCodeFilter, formDateFilter } from "@monorepo/utils/src/api/convertFiltersToApi";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & { fieldFilters: { creationDateFrom?: string; creationDateTo?: string; status?: string } }
): void => {
  formDateFilter(resultFilters, "date");
  formDateFilter(resultFilters, "time");
  convertOikCodeFilter(resultFilters);
  if ((resultFilters.fieldFilters.tkType as string[]).includes("INVENTORY_EAD")) {
    resultFilters.fieldFilters.tkType = ["INVENTORY", "EAD"];
  }
};
