import { ComponentInstance, onMounted, Ref } from "@vue/composition-api";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { isEmpty } from "lodash";

interface IUseOikAutocomplete {
  getItems: (value: string) => void;
}

const useOikAutocomplete = (
  root: ComponentInstance,
  moduleName: string,
  currentFilters: Ref<Record<string, unknown>>,
  items: Ref<IAutocompleteElement[]>,
  isLoading: Ref<boolean>
): IUseOikAutocomplete => {
  const store = root.$store;

  const getOikAutocompleteItems = async (value: string) => {
    return await store.dispatch(`${moduleName}/getOikAutocompleteItems`, value);
  };

  const restoreOikComplete = () => {
    if (!isEmpty(currentFilters.value.oikCode)) {
      items.value = [currentFilters.value.oikCode as IAutocompleteElement];
    }
  };

  const getItems = async (value: string) => {
    isLoading.value = true;
    items.value = await getOikAutocompleteItems(value);
    isLoading.value = false;
  };

  onMounted(() => {
    restoreOikComplete();
  });

  return {
    getItems,
  };
};

export default useOikAutocomplete;
