import { ITKElement } from "@monorepo/monitoring/src/views/TKLogView/types/tkElement";
import { ITransportContainerLog } from "@monorepo/monitoring/src/views/TKLogView/types/transportContainerLog";
import { convertStatusesToTitle } from "@monorepo/utils/src/utils/convertStatusesToTitle";
import moment from "moment";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";
import { ICell } from "@monorepo/monitoring/src/views/TKLogView/utils/getCells";

type result = ITKElement & {
  status?: null | TK_STATUS;
  notBlockedMessages?: string[][];
  notBlockedSteps?: number[];
  steps?: number;
  type?: string;
  description?: string;
  defaultStatus?: string;
  statusMessage?: string;
};

export const convertApiItemToUi = (data: ITKElement[], cells: ICell[], statusesObj: Record<string, string>): result[] => {
  const steps = cells.map((item) => item.order);
  return data
    .map((element: ITKElement) => {
      try {
        const logs: Record<string, ITransportContainerLog[]> = element.transportContainerLogs.reduce(
          (res: Record<string, ITransportContainerLog[]>, element: ITransportContainerLog) => {
            res[element.step?.order as string] = [...(res[element.step?.order as string] || []), element];
            return res;
          },
          {}
        );
        return Object.assign(element, logs);
      } catch (e) {
        return element;
      }
    })
    .map((element) => {
      try {
        const result: result = {
          ...element,
          id: element.id,
          statusMessage: element.status ? statusesObj[element.status] || "" : "",
          steps: 0,
          notBlockedMessages: [],
          notBlockedSteps: [],
          receivedAt: element.receivedAt ? moment(element.receivedAt).format("YYYY-MM-DD HH:mm") : "-",
          status: TK_STATUS.SUCCESS,
          defaultStatus: element.status,
          description: "",
        };

        const sliceNumber = steps.length;
        steps.slice(0, sliceNumber).every((step, index: number) => {
          const steps = ((element as any)[step as any] || []).sort((a: { date: string }, b: { date: string }) => {
            moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
          });
          const stepItem = steps[0];

          if (steps.length > 1) {
            result.notBlockedMessages = [
              ...(result?.notBlockedMessages || []),
              [
                "История перезапусков контейнера:",
                ...steps.map((step: { date: string; description: string }) => `${moment(step.date).format("DD.MM.YYYY HH:mm")}: ${step.description}`),
              ],
            ];
            result.notBlockedSteps = [...(result?.notBlockedSteps || []), index];
          }

          if (stepItem?.result === false && !stepItem?.isNotBlocked) {
            result.steps = index + 1;
            result.status = TK_STATUS.ERROR;
            result.description = stepItem?.description;
            return false;
          }

          if (stepItem?.isNotBlocked) {
            result.notBlockedMessages = [...(result?.notBlockedMessages || []), [stepItem?.description]];
            result.notBlockedSteps = [...(result?.notBlockedSteps || []), index];
          }

          if (!stepItem) {
            if (result.defaultStatus === TK_STATUS.PENDING) {
              result.steps = index + 1;
              result.status = TK_STATUS.PENDING;
              return false;
            }

            if (result.defaultStatus === TK_STATUS.ERROR) {
              result.steps = index;
              result.status = TK_STATUS.ERROR;
              return false;
            }

            result.steps = index;
            result.status = TK_STATUS.SUCCESS;
            return false;
          }

          result.steps = index + 1;
          result.status = TK_STATUS.SUCCESS;
          result.description = stepItem?.description;
          return true;
        });

        return result;
      } catch (e) {
        return element;
      }
    });
};
