











import { defineComponent } from "@vue/composition-api";
import { Type } from "@monorepo/monitoring/src/views/TKLogView/constants/types";

const typeText = {
  [Type.INVENTORY]: "Опись",
  [Type.EAD]: "Документ",
  [Type.RESPONSE_OIK]: "Ответ в ОИК",
  [Type.DICTIONARY_OIK]: "Обновление справочников в ОИК",
  [Type.RESPONSE_DICTIONARY_OIK]: "Обновление справочников - ответ из ОИК",
  [Type.MEDO_RECEIPT]: "Квитанция МЭДО",
  [Type.OTHER]: "Иные",
  [Type.INVENTORY_PROJECT]: "Проект описи",
  [Type.ACT]: "Акт приёма передачи дел",
  [Type.NOMENCLATURE]: "Номенклатура",
};

const typeIcon = {
  INCOME: "mdi-chevron-double-right",
  OUTCOME: "mdi-chevron-double-left",
};
export default defineComponent({
  name: "Type",
  props: {
    type: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "",
    },
  },
  computed: {
    title(): string {
      return typeText[this.type as Type] || "";
    },
    tooltip(): string {
      return typeText[this.type as Type] || "";
    },
    icon(): string {
      return typeIcon[this.direction as "INCOME" | "OUTCOME"] || "mdi-chevron-double-right";
    },
  },
});
