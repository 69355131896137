






































import { defineComponent, computed } from "@vue/composition-api";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import { getFormattedHeaders } from "@monorepo/monitoring/src/views/TKLogView/utils/getFormattedHeaders";

type headerMin = { width: number; defaultWidth: number; id: string; value: string };

export default defineComponent({
  name: "TableHeader",
  extends: TableHeader,
  mounted() {
    const elementTarget = document.getElementById("table-header-wrap") as HTMLElement;
    this.$emit("isScrolledToEnd", !(elementTarget?.scrollWidth - (elementTarget?.scrollLeft + elementTarget?.offsetWidth)));
  },
  computed: {
    headers() {
      return this.setupHeaders;
    },
  },
  methods: {
    getFormattedHeaders(headers: headerMin[], visibleHeaders: headerMin[], difference: number) {
      visibleHeaders = visibleHeaders.filter((item) => item.value !== "checkbox");
      return headers.map((item: headerMin) => {
        const el = visibleHeaders.find((header: headerMin) => header.id === item?.id);
        if (el) {
          item.width = item?.defaultWidth + difference / visibleHeaders.length;
          item.width = item.width < 37 ? 37 : item.width;
        }
        return item;
      });
    },
    onScrollCb(e: Event) {
      const elementTarget = e.target as HTMLElement;
      const element = document.getElementById("virtual-scroll-table");
      if (element) {
        element.scrollLeft = elementTarget?.scrollLeft;
      }
      this.$emit("isScrolledToEnd", !(elementTarget?.scrollWidth - (elementTarget?.scrollLeft + elementTarget?.offsetWidth)));
    },
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => (props as { tableHeaders: unknown[] }).tableHeaders);
    const { headers, stretchHeaders } = useTableCells(root, "TKLogView", tableHeaders, 1120, false, false, {}, getFormattedHeaders);
    return {
      setupHeaders: headers,
      stretchHeaders,
    };
  },
});
