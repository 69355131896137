import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/monitoring/src/views/TKLogView/utils/convertFiltersToApi";
import { ITKElement } from "@monorepo/monitoring/src/views/TKLogView/types/tkElement";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { TK_DIRECTION, TK_STATUS } from "@monorepo/utils/src/types/tkStatus";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi } from "@monorepo/utils/src/api/convertFiltersToApi";
import { ITransportContainerLog } from "@monorepo/monitoring/src/views/TKLogView/types/transportContainerLog";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions, IVuexContext } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";
import axios from "axios";

export interface IStepContainerItem {
  groups: { name: string; steps: { name: string; descr: string; order: 1 }[] }[];
  type: { code: string; name: string };
}

interface ITableState {
  filters: ITableFiltersObject;
  section?: Sections;
  libraries: {
    statuses: Record<string, string>[];
    statusesAll: Record<string, string>[];
    types: Record<string, string>[];
    containerSteps: Record<string, IStepContainerItem["groups"]>;
  };
  totalLength: number;
  data: ITKElement[];
  searchTemplates: ISearchTemplate[];
  infiniteId: string;
  isOpenFilters: boolean;
  autorefresh: boolean;
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  fieldFilters: {
    [fields.TK_DIRECTION]: ["INCOME"],
    [fields.TK_TYPE]: [],
  },
  initMessagesLength: 50,
  openedId: null,
  sort: {},
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_TK_LOG_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_TK_LOG_XLS_LIST;
    default:
      return QUERY_PATH.GET_TK_LOG_PDF_LIST;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    libraries: {
      statuses: [],
      statusesAll: [],
      types: [],
      containerSteps: {},
    },
    isOpenFilters: false,
    autorefresh: false,
    section: Sections.CONTAINER_LOG,
    filters: defaultFilters(),
    searchTemplates: [],
    infiniteId: new Date().toString(),
    totalLength: 0,
    data: [],
    isTableLoading: false,
    isLoadingToggleFilters: false,
    isLoadingChangeAutorefresh: false,
  }),
  mutations: {
    ...baseMutations,
    sliceAllTypes(state: ITableState) {
      state.libraries.types = state.libraries.types.slice(1);
    },
    addTypes(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.types = [{ code: "", name: "Все типы ТК" }].concat(payload || []).map((item) => ({ value: item.code, title: item.name }));
    },
    addContainerSteps(state: ITableState, payload: IStepContainerItem[]) {
      state.libraries.containerSteps = (payload || []).reduce((result, item) => {
        return { ...result, [item.type.code]: item.groups };
      }, {});
    },
    addStatuses(state: ITableState, payload: { code: TK_STATUS; title: string }[]) {
      state.libraries.statuses = (payload || []).map((item) => ({ value: item.code, title: item.title }));
    },
    addStatusesAll(state: ITableState, payload: { code: TK_STATUS; title: string }[]) {
      state.libraries.statusesAll = (payload || []).map((item) => ({ value: item.code, title: item.title }));
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addData(
      state: ITableState,
      payload: {
        data: ITKElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      const newEvents = payload.data.map((item) => {
        return {
          ...item,
          transportContainerLogs: item.transportContainerLogs.map((element: ITransportContainerLog) => ({
            ...element,
            id: element.id,
            checkId: element.check?.id ?? "",
          })),
        };
      });

      state.data = payload.isReset ? newEvents : state.data.concat(newEvents);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getTkElement(info, id: string) {
      const { data } = await getQuery<ITKElement[]>(`${QUERY_PATH.GET_TK_ELEMENT_CARD}/${id}`);
      return data;
    },
    async getExportData({ state, dispatch }, payload: "CSV" | "PDF" | "XLSX") {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      const queryParams = encodeQueryData(params);
      dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
        root: true,
      });
    },
    async reloadTk(info, id: number) {
      return await axios.post(`${QUERY_PATH.RETRY_CONTAINER}`, {}, { params: { id } });
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_ELEMENTS, { ...params, offset: 0 }).finally(() => {
        commit("setTableLoading", false);
      });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_ELEMENTS, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getStatuses({ commit }) {
      const { data } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_STATUSES, { direction: TK_DIRECTION.INCOME }, false);
      if (data !== null) {
        commit("addStatuses", data);
      }
    },
    async getStatusesAll({ commit }) {
      const { data } = await getQuery<ITKElement[]>(QUERY_PATH.GET_TK_STATUSES);
      if (data !== null) {
        commit("addStatusesAll", data);
      }
    },
    async getTKTypes({ commit }) {
      const { data } = await getQuery<string[]>(QUERY_PATH.GET_TK_INCOMING_TYPES);
      if (data !== null) {
        commit("addTypes", data);
      }
    },
    async getContainerSteps({ commit }) {
      const { data } = await getQuery<IStepContainerItem[]>(QUERY_PATH.CONTAINER_STEPS);
      if (data !== null) {
        commit("addContainerSteps", data);
      }
    },
    async getCells({ rootGetters, getters, commit }: IVuexContext, payload: string) {
      try {
        const { data } = await getQuery<ISection>(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${rootGetters["auth/user"].name}/${payload}`);
        commit(`setSectionCells`, data ?? {});
        await axios.put(`${getFullPath(QUERY_PATH.GET_COLL_STATE)}/${getters.sectionCells?.id}`, {
          autorefresh: getters.sectionCells?.autorefresh,
          showFilter: getters.sectionCells?.showFilter,
          columnStates: [],
        });
        data.columnStates.length = 0;
        return data ?? null;
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    ...baseGetters,
    statuses(state: ITableState) {
      return state.libraries.statuses;
    },
    statusesAll(state: ITableState) {
      return state.libraries.statusesAll;
    },
    types(state: ITableState) {
      return state.libraries.types;
    },
    containerSteps(state: ITableState) {
      return state.libraries.containerSteps;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
