export const directionFiltersItems = [
  {
    title: "Входящий",
    value: "INCOME",
  },
  {
    title: "Исходящий",
    value: "OUTCOME",
  },
];
