import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";

export const cellElementWidth = 28;

export const tableHeaders = [
  { text: "Идентификатор ТК", defaultWidth: 150, value: fields.TK_ID, isSorted: false, isHandle: false },
  { text: "Статус тк", defaultWidth: 125, value: fields.STATUS_MESSAGE, isSorted: false, isHandle: false },
  { text: "Дата", defaultWidth: 130, value: fields.RECEIVED_AT, isSorted: true, isHandle: false },
  { text: "", defaultWidth: 150, value: fields.TK_TYPE_FIELD, isSorted: false, isHandle: false },
];
