import { ICheckItem } from "@monorepo/monitoring/src/views/TKLogView/types/checkItem";
import { cellElementWidth } from "@monorepo/monitoring/src/views/TKLogView/constants/tableHeaders";

export interface ICell {
  text: string;
  tooltip: string;
  defaultWidth: number;
  value: string;
  isSorted: boolean;
  order?: number;
  isHandle: boolean;
  isNeedSelection: boolean;
  isTextAlignCenter: boolean;
}

export const getCells = (statuses: ICheckItem[] = []): ICell[] => {
  const checkStatuses = [...statuses]
    .sort((el1: ICheckItem, el2: ICheckItem) => el1.order - el2.order)
    .map((el: ICheckItem, index: number) => ({
      text: `${index + 1}`,
      order: el.order,
      tooltip: el.status,
      defaultWidth: cellElementWidth,
      value: el.id,
      isSorted: false,
      isHandle: false,
      isNeedSelection: true,
      isTextAlignCenter: true,
    }));
  return [...checkStatuses];
};
