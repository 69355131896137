


































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "OikAutocomplete",
  data() {
    return {
      search: null as string | null,
      isLoadDefaultItems: false,
      isFocus: false,
    };
  },
  model: {
    prop: "selected",
    event: "change",
  },
  props: {
    selected: {
      type: Object,
      default() {
        return {};
      },
    },
    rules: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEmptyValue() {
      return !this.selected || !Object.keys(this.selected).length;
    },
  },
  methods: {
    loadDefaultItems() {
      if (!this.search && this.isEmptyValue) {
        this.emitSearchText("");
      }
    },
    fieldOnBlur() {
      this.isFocus = false;
      if (this.search && this.isEmptyValue) {
        this.isLoadDefaultItems = true;
        this.search = "";
      }
    },
    fieldOnFocus() {
      this.isFocus = true;
      this.loadDefaultItems();
    },
    emitSearchText(val: string | null) {
      if (this.isLoadDefaultItems) {
        this.isLoadDefaultItems = false;
        return;
      }
      (val !== this.selected?.code || val !== this.selected?.name) && this.$emit("search-text", val || "");
    },
  },
  watch: {
    search(val) {
      this.emitSearchText(val);
    },
    isEmptyValue(value: boolean) {
      if (value && this.search) {
        this.isLoadDefaultItems = true;
        this.search = "";
      }
    },
  },
});
