var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{ref:"table",staticClass:"monitoring-table monitoring-table__content tk-journal",attrs:{"id":"virtual-scroll-table"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('transition-group',{attrs:{"name":"list","tag":"tbody"}},_vm._l((_vm.resultData),function(event){return _c('tr',{key:event[_vm.fields.TK_ID],style:({
          background: _vm.openedId === event[_vm.fields.TK_ID] ? '#EFEFEF' : '',
        }),attrs:{"data-test-id":"tableRow"}},[_vm._l((_vm.tableHeaders.slice(0, 4)),function(header,index){return _c('td',{key:("" + (event[_vm.fields.TK_ID]) + (header.value)),class:[
            'monitoring-table__td pa-0',
            {
              'pl-3': index,
              'pr-3': header.value !== _vm.fields.TK_TYPE_FIELD,
              'tk-journal__td_first': !index,
              'tk-journal__td_error': !index && event[_vm.fields.TK_STATUS] === _vm.TK_STATUS.ERROR,
              'tk-journal__td_success': !index && event[_vm.fields.TK_STATUS] === _vm.TK_STATUS.SUCCESS,
            } ],style:({ width: ((_vm.getCellWidth(index)) + "px"), 'min-width': ((_vm.getCellWidth(index)) + "px") }),attrs:{"data-test-id":"tableCell"}},[(!index)?_c('OpenCardButton',{class:[{ 'open-card-button__fixed': event[_vm.fields.TK_STEPS] > _vm.rightLimit }],style:({ left: event[_vm.fields.TK_STEPS] > _vm.rightLimit ? (_vm.buttonLeftPadding + "px") : '' }),on:{"open":function($event){return _vm.openModal('openModal', event)}}}):_vm._e(),(!index && event.retryable)?_c('v-btn',{class:['open-card-button open-card-button-refresh pl-4 pr-4'],style:({ left: (_vm.buttonLeftPadding + "px") }),attrs:{"disabled":_vm.isDisableReload,"width":"200","height":"40","color":"white"},on:{"click":function($event){return _vm.handleReloadTk(event.id)}}},[_vm._v(" Перезапустить ")]):_vm._e(),(header.value === _vm.fields.TK_TYPE_FIELD)?_c('Type',{attrs:{"type":event[_vm.fields.TK_TYPE_FIELD] || '',"direction":event[_vm.fields.TK_DIRECTION] || ''}}):(header.value === _vm.fields.TK_ID)?_c('IdCell',{attrs:{"value":event[_vm.fields.TK_ID],"is-link":header.isLink}}):_c('span',[_vm._v(" "+_vm._s(event[header.value])+" ")])],1)}),_c('td',{class:"monitoring-table__td pa-0",style:({ width: ((_vm.stepCellWidth * (event.steps > _vm.cells.length ? _vm.cells.length : event.steps)) + "px") }),attrs:{"data-test-id":"tableCell","colspan":event.steps}},[_c('div',{class:[
              'tk-journal__element pl-3 d-flex align-center justify-end',
              {
                'error-status': event.status === _vm.TK_STATUS.ERROR,
                'success-status': event.status === _vm.TK_STATUS.SUCCESS,
                'waiting-status': event.status === _vm.TK_STATUS.PENDING,
                'not-last': _vm.tableHeaders.length > event.steps + 2,
              } ],style:({ paddingRight: (_vm.imgRightPadding + "px") })},[(event.status === _vm.TK_STATUS.ERROR)?_c('v-tooltip',{attrs:{"offset-x":"","offset-y":"","fixed":"","z-index":_vm.tooltipZIndex,"left":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.errorIcon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"tk-journal__tooltip"},[_c('div',{staticClass:"tk-journal__tooltip-title mb-2"},[_vm._v(_vm._s(_vm.getStepTitle(event.steps - 1)))]),_c('div',{staticClass:"tk-journal__tooltip-text mb-2"},[_vm._v(" "+_vm._s(event.description)+" ")])])]):(event.status === _vm.TK_STATUS.SUCCESS)?_c('v-tooltip',{attrs:{"offset-x":"","offset-y":"","fixed":"","z-index":_vm.tooltipZIndex,"left":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.successIcon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"tk-journal__tooltip"},[_c('div',{staticClass:"tk-journal__tooltip-title mb-2"},[_vm._v(_vm._s(_vm.getStepTitle(event.steps - 1)))]),_c('div',{staticClass:"tk-journal__tooltip-text"},[_vm._v(" "+_vm._s(event.description)+" ")])])]):_c('v-img',{attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.pendingIcon}}),_vm._l((event.notBlockedSteps || []),function(step,index){return _c('v-menu',{key:step + index,attrs:{"open-on-hover":"","offset-x":"","offset-y":"","fixed":"","z-index":_vm.tooltipZIndex,"left":"","close-delay":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"tk-journal__not-block-img",style:({ left: ((_vm.getMessageIconLeft(step) - (step + 1 === event.steps ? 30 : 20)) + "px") }),attrs:{"contain":"","max-width":"20","max-height":"20","src":_vm.messageIcon}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{staticClass:"tk-journal__tooltip tk-journal__tooltip_black-background pa-2"},[_c('div',{staticClass:"tk-journal__tooltip-title mb-2"},[_vm._v(_vm._s(_vm.getStepTitle(step)))]),_vm._l(((event.notBlockedMessages || [])[index] || []),function(info,indexMessage){return _c('div',{key:indexMessage,staticClass:"tk-journal__tooltip-text mb-2"},[_vm._v(" "+_vm._s(info)+" ")])})],2)])})],2)]),(_vm.tableHeaders.length > event.steps + 3)?_c('td',{staticClass:"monitoring-table__td pa-0",style:({ width: ((_vm.getRowLastDivWidth(event.steps)) + "px") }),attrs:{"data-test-id":"tableCell","colspan":_vm.tableHeaders.length - (event.steps + 3)}}):_vm._e()],2)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }