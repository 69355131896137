import { ComponentInstance } from "@vue/composition-api";

const useInitCheckStatusesLib = (root: ComponentInstance): Promise<unknown[]> => {
  const store = root.$store;
  return Promise.all([
    store.dispatch("TKLogView/getStatuses"),
    store.dispatch("TKLogView/getTKTypes"),
    store.dispatch("TKLogView/getContainerSteps"),
    store.dispatch("TKLogView/getStatusesAll"),
  ]);
};

export default useInitCheckStatusesLib;
