export interface ITKLogFields {
  TK_ID: string;
  TK_DATE: string;
  TK_DATE_FROM: string;
  TK_DATE_TO: string;
  TK_TIME_FROM: string;
  TK_TIME_TO: string;
  TK_STATUS: string;
  TK_TYPE: string;
  TK_TYPE_FIELD: string;
  OIK_CODE: string;
  TK_OIK_ID: string;
  STATUS_MESSAGE: string;
  TK_DIRECTION: string;
  RECEIVED_AT: string;
  TK_STEPS: string;
}

export const fields: ITKLogFields = {
  TK_ID: "id",
  TK_DATE: "date",
  TK_TYPE: "tkType",
  TK_TYPE_FIELD: "type",
  TK_DATE_FROM: "dateFrom",
  TK_DATE_TO: "dateTo",
  TK_TIME_FROM: "timeFrom",
  TK_TIME_TO: "timeTo",
  TK_DIRECTION: "direction",
  TK_STATUS: "status",
  OIK_CODE: "oikCode",
  TK_OIK_ID: "tkOikGuid",
  STATUS_MESSAGE: "statusMessage",
  RECEIVED_AT: "receivedAt",
  TK_STEPS: "steps",
};
