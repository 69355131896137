




















































































































































import { defineComponent, computed } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { TK_STATUS } from "@monorepo/utils/src/types/tkStatus";
import { ICell } from "@monorepo/monitoring/src/views/TKLogView/utils/getCells";
import { convertApiItemToUi } from "@monorepo/monitoring/src/views/TKLogView/utils/convertApiItemToUi";
import OpenCardButton from "@monorepo/uikit/src/components/tableViews/OpenCardButton.vue";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import Type from "./Type.vue";
import { debounce } from "lodash";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import { getFormattedHeaders } from "@monorepo/monitoring/src/views/TKLogView/utils/getFormattedHeaders";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export enum EVENT_TYPE {
  ERROR = "ERROR",
}

export default defineComponent({
  name: "Table",
  components: {
    OpenCardButton,
    IdCell,
    Type,
  },
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    cells: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("TKLogView", ["data", "containerSteps", "openedId", "fieldFilters", "statusesAll"]),
    statusesObj(): Record<string, string> {
      return this.statusesAll.reduce((result: Record<string, string>, item: { value: string; title: string }) => {
        result[item.value] = item.title;
        return result;
      }, {} as Record<string, string>);
    },
    resultData(): ReturnType<typeof convertApiItemToUi> {
      return convertApiItemToUi(this.data, this.cells as ICell[], this.statusesObj);
    },
    resizeKoef(): number {
      return this.containerWidthAfterResize / this.containerWidthBeforeResize;
    },
    stepCellWidth(): number {
      if ((this as unknown as { headers: Record<string, unknown>[] }).headers.length > 3) {
        return ((this as unknown as { headers: Record<string, unknown>[] }).headers[4]?.width as number) * this.resizeKoef || 0;
      } else {
        return ((this as unknown as { tableHeaders: Record<string, unknown>[] }).tableHeaders[4]?.width as number) * this.resizeKoef || 0;
      }
    },
    buttonLeftPadding(): number {
      return (
        (this as unknown as { headers: Record<string, unknown>[] }).headers
          .slice(0, 4)
          .reduce((sum: number, current) => sum + (current as { width: number }).width, 0) * this.resizeKoef
      );
    },
    imgRightPadding(): number {
      return this.stepCellWidth / 2 - 10;
    },
    getMessageIconLeft(): (step: number) => number {
      return (step) => this.stepCellWidth * (step + 0.5) - 10;
    },
    getRowLastDivWidth(): (steps: number) => number {
      return (steps) => this.stepCellWidth * (this.tableHeaders.length - (steps + 3));
    },
    getCellWidth(): (index: number) => number {
      if ((this as unknown as { headers: Record<string, unknown>[] }).headers.length > 3) {
        return (index) => ((this as unknown as { headers: Record<string, unknown>[] }).headers[index].width as number) * this.resizeKoef;
      } else {
        return (index) => ((this as unknown as { tableHeaders: Record<string, unknown>[] }).tableHeaders[index].width as number) * this.resizeKoef;
      }
    },
  },
  data() {
    return {
      TK_STATUS,
      successIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
      errorIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/errorIcon.svg`,
      pendingIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/pendingIcon.svg`,
      messageIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/messageIcon.svg`,
      tooltipZIndex: 200,
      rightLimit: 8,
      isDisableReload: false,
      containerWidthBeforeResize: 0,
      containerWidthAfterResize: 0,
      containerWidthOnStart: 0,
      fields,
    };
  },
  methods: {
    ...mapActions("TKLogView", ["reloadTk", "refreshScroll"]),
    openError(id: string) {
      this.$router.push({ path: "/monitoring/event-log", query: { obj: id, type: EVENT_TYPE.ERROR } });
    },
    async handleReloadTk(id: number) {
      try {
        this.isDisableReload = true;
        await this.reloadTk(id);
        this.refreshScroll();
        showNotification("Перезапущена обработка контейнера", NOTIFICATION_STATUS.SUCCESS);
      } catch (e) {
        showNotification("Ошибка перезапуска обработки контейнера");
      } finally {
        this.isDisableReload = false;
      }
    },
    openModal(value: string, event: Record<string, unknown>) {
      switch (value) {
        case "openModal":
          if (event.id) {
            this.$store.dispatch("TKLogView/addOpenedId", event.id);
            this.$emit(value);
          }
          break;
        default:
          break;
      }
    },
    getStepTitle(step: number): string {
      const element = (this.cells as ICell[])[step];
      return element?.tooltip ?? "";
    },
    getContainerWidth(): number {
      const container = document.getElementById("table-header-wrap");
      if (container) {
        return container.clientWidth;
      } else {
        return 0;
      }
    },
    updateHeaders() {
      let width = this.getContainerWidth();
      if (width < this.containerWidthOnStart) {
        this.containerWidthAfterResize = this.containerWidthOnStart;
      } else {
        this.containerWidthAfterResize = width;
      }
      this.$emit("changeWidthKoef", this.resizeKoef);
    },
    updateHeadersDebounce: debounce(function (this: { updateHeaders: () => void }) {
      this.updateHeaders();
    }, 50),
  },
  mounted() {
    this.containerWidthOnStart = this.getContainerWidth();
    this.containerWidthBeforeResize = this.containerWidthOnStart;
    this.containerWidthAfterResize = this.containerWidthOnStart;
    window.addEventListener("resize", this.updateHeadersDebounce);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateHeadersDebounce);
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => props.tableHeaders);
    const { headers } = useTableCells(root, "TKLogView", tableHeaders, 1120, false, false, {}, getFormattedHeaders);
    return {
      headers,
    };
  },
});
