



















import { defineComponent, computed } from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";
import { mapGetters } from "vuex";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";
import { getFormattedHeaders } from "@monorepo/monitoring/src/views/TKLogView/utils/getFormattedHeaders";

const typeNames = {
  medo: "МЭДО",
  ched: "ЦХЭД",
  ac: "АК",
};

const defaultCells = {
  medo: 4,
  ched: 8,
  ac: 2,
};

export default defineComponent({
  name: "AdditionalHeaders",
  props: {
    stepsCount: {
      type: Number,
      default: 14,
    },
    widthCell: {
      type: Number,
      default: 40,
    },
    activeCells: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters("TKLogView", ["containerSteps", "fieldFilters"]),
    tkStatus(): string {
      return (this.fieldFilters[fields.TK_TYPE] as string[])?.[0] || "";
    },
    categories(): { title: string; steps: number }[] {
      return (this.containerSteps || {})[this.tkStatus]
        ? (this.containerSteps || {})[this.tkStatus].map((item: { name: string; steps: { name: string; descr: string; order: 1 }[] }) => {
            return {
              title: typeNames[item.name as keyof typeof defaultCells] || item.name,
              steps: item.steps?.length || 1,
            };
          })
        : Object.entries(typeNames).map(([key, value]) => ({
            title: value,
            steps: defaultCells[key as keyof typeof defaultCells],
          }));
    },
    steps(): { steps: number; title: string; key: string }[] {
      let count = this.stepsCount;
      return this.categories
        .map((item) => {
          if (count) {
            let tmpCount = count > item.steps ? item.steps : count;
            count = count > item.steps ? count - item.steps : 0;
            return {
              title: item.title,
              key: uuid(),
              steps: tmpCount,
            };
          } else {
            return {
              title: "",
              key: uuid(),
              steps: 0,
            };
          }
        })
        .filter((item) => !!item.steps);
    },
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => props.tableHeaders);
    const { headers } = useTableCells(root, "TKLogView", tableHeaders, 1120, false, false, {}, getFormattedHeaders);
    return {
      headers,
    };
  },
});
