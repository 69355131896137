








































































import { viewTitle } from "@monorepo/utils/src/variables/projectsData/TKLogView/viewTitle";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/monitoring/src/views/TKLogView/components/Table.vue";
import TableHeader from "@monorepo/monitoring/src/views/TKLogView/components/TableHeader.vue";
import Filters from "@monorepo/monitoring/src/views/TKLogView/components/Filters.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import SelectedColumn from "@monorepo/monitoring/src/views/TKLogView/components/SelectedColumn.vue";
import AdditionalHeaders from "@monorepo/monitoring/src/views/TKLogView/components/AdditionalHeaders.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/monitoring/src/views/TKLogView/constants/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as TKLogView } from "@monorepo/monitoring/src/store/modules/TKLogView";
import useInitCheckStatusesLib from "@monorepo/monitoring/src/views/TKLogView/composables/useInitCheckStatusesLib";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/monitoring/src/views/TKLogView/constants/breadcrumbs";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { getCells, ICell } from "@monorepo/monitoring/src/views/TKLogView/utils/getCells";
import TKLogInfoModal from "@monorepo/monitoring/src/views/TKLogView/components/TKLogInfoModal.vue";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";

import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";

export interface IData {
  breadcrumbs: typeof breadcrumbs;
  viewTitle: string;
  sectionName: string;
  selection: null | { left: number; width: number };
  widthKoef: number;
  isScrolledToEnd: boolean;
}

export default defineComponent({
  name: "TKLogView",
  components: {
    TableSettings,
    AutoRefreshButton,
    ExportFooterButton,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    AdditionalHeaders,
    Breadcrumbs,
    SortPanelContainer,
    SelectedColumn,
    TKLogInfoModal,
  },
  data(): IData {
    return {
      sectionName: "TKLogView", // sectionName === store module name
      breadcrumbs,
      viewTitle,
      selection: null,
      widthKoef: 1,
      isScrolledToEnd: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters("TKLogView", [
      "fieldFilters",
      "containerSteps",
      "isOpenFilters",
      "infiniteId",
      "data",
      "openedId",
      "totalLength",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
    ]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.TK_JOURNAL_EXPORT);
    },
    tkStatus(): string {
      return (this.fieldFilters[fields.TK_TYPE] as string[])?.[0] || "";
    },
    cells(): ICell[] {
      const statuses = ((this.containerSteps || {})[this.tkStatus] || (this.containerSteps || {})["common"] || [])
        .reduce((result: { name: string; descr: string; order: 1 }[], item: { steps: { name: string; descr: string; order: 1 }[] }) => {
          return result.concat(item?.steps || []);
        }, [])
        .map(({ order, descr: status, name: id }: { name: string; descr: string; order: 1 }) => ({ order, id, status }));
      return getCells(statuses);
    },
    cellsResult(): ICell[] {
      return this.cells;
    },
    tableHeaders(): Record<string, unknown>[] {
      return [...tableHeaders, ...this.cellsResult];
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
  },
  methods: {
    ...mapActions("TKLogView", ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    handleIsScrolledToEndHeaders(event: boolean) {
      this.isScrolledToEnd = event;
    },
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data?.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data?.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    deactivateSelection() {
      this.selection = null;
    },
    activateSelection({
      header,
      headers,
      scrollLeft,
    }: {
      header: { width: number; text: number; isNeedSelection: boolean };
      headers: Record<string, string | number | boolean | undefined>[];
      scrollLeft: number;
    }) {
      if (header.isNeedSelection) {
        const staticWidth = headers.slice(0, 4).reduce((sum, current) => sum + (current.width as number), 0);
        this.selection = {
          left: staticWidth + (+header.text - 1) * header.width - scrollLeft,
          width: header.width,
        };
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch("TKLogView/addOpenedId", null);
      }
    },
    changeWidthKoef(koef: number) {
      this.widthKoef = koef;
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);

    useInitTableStoreModule(root, "TKLogView", TKLogView as Module<unknown, unknown>);
    useOpenHistoryMode(root, "TKLogView");
    useSetStartCells(root, "TKLogView", root.$store.getters[`TKLogView/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, "TKLogView");
    });
    useInitCheckStatusesLib(root)
      .then(() => {
        if (
          ![authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST, authorities.TK_DOCUMENTS_LIST].every((item) =>
            isAuthorityExist(root.$store.state.auth.user, item)
          )
        ) {
          root.$store.commit(`TKLogView/sliceAllTypes`);
          const value = root.$store.getters[`TKLogView/types`]?.[0]?.value;
          root.$store.dispatch(`TKLogView/addFilter`, { [fields.TK_TYPE]: value ? [value] : [] });
        }
        return useSetStartFilters(root, "TKLogView", ["id"]);
      })
      .then(() => {
        useInitEducationPanel(root);
        filtersLoaded.value = true;
        useSetStartModal(root, "TKLogView");
      });

    return {
      filtersLoaded,
    };
  },
});
