import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.TK_ID]: "",
  [fields.TK_OIK_ID]: "",
  [fields.TK_STATUS]: [],
  [fields.OIK_CODE]: {},
  [fields.TK_DATE_FROM]: "",
  [fields.TK_DATE_TO]: "",
  [fields.TK_TIME_FROM]: "",
  [fields.TK_TIME_TO]: "",
  [fields.TK_TYPE]: [],
});
