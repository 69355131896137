import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=86d2baf4&scoped=true&"
import script from "./Table.vue?vue&type=script&lang=ts&"
export * from "./Table.vue?vue&type=script&lang=ts&"
import style0 from "./Table.vue?vue&type=style&index=0&id=86d2baf4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d2baf4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VImg,VMenu,VSimpleTable,VTooltip})
