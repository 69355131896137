









































































































import { defineComponent, ref } from "@vue/composition-api";
import { currentFilters as setCurrentFilters } from "../utils/defaultCurrentFilters";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import TextFilter from "@monorepo/uikit/src/components/tableViews/TextFilter.vue";
import DatePicker from "@monorepo/uikit/src/components/tableViews/DatePicker.vue";
import TimePicker from "@monorepo/uikit/src/components/tableViews/TimePicker.vue";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import OikAutocomplete from "@monorepo/uikit/src/components/tableViews/OikAutocomplete.vue";
import { mapActions, mapGetters } from "vuex";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { autocompleteElement, IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";
import { fields } from "@monorepo/utils/src/variables/projectsData/TKLogView/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/TKLogView/filtersElements";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { directionFiltersItems } from "../constants/directionFIlterItems";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import useOikAutocomplete from "@monorepo/utils/src/composables/useOikAutocomplete";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";

export default defineComponent({
  components: {
    TextFilter,
    DatePicker,
    TimePicker,
    SelectFilter,
    OikAutocomplete,
    FindButton,
    ToggleFilters,
    ClearFilters,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sectionName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      autocompleteElement,
      fields,
      filtersElements,
      directionFiltersItems,
      educationIds,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("TKLogView", ["statuses", "fieldFilters", "searchTemplates", "types", "isTableLoading", "isLoadingToggleFilters"]),
    isShowFilter(): boolean {
      return isAuthorityExist(this.user, [authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST]);
    },
    tkDirection: {
      get(): string[] {
        return this.currentFilters[fields.TK_DIRECTION] as string[];
      },
      set(value: string[]): void {
        if (!value.length) {
          this.currentFilters[fields.TK_DIRECTION] = [directionFiltersItems[0].value];
        } else {
          this.currentFilters[fields.TK_DIRECTION] = value;
        }
      },
    },
    tkStatus: {
      get(): string {
        return (this.currentFilters[fields.TK_TYPE] as string[])[0] || "";
      },
      set(
        this: {
          setTKStatus: (value: string) => void;
        },
        value: string
      ): void {
        this.setTKStatus(value);
      },
    },
    dateFrom: {
      get(): string {
        return this.currentFilters[fields.TK_DATE_FROM] as string;
      },
      set(value: string): void {
        if (this.currentFilters[fields.TK_DATE_TO]) {
          this.currentFilters[fields.TK_DATE_TO] = "";
          this.currentFilters[fields.TK_DATE_TO] = "";
        }
        this.currentFilters[fields.TK_DATE_FROM] = value;
      },
    },
  },
  methods: {
    ...mapActions("TKLogView", ["clearFilters", "addFilter", "getOikAutocompleteItems", "saveSearchTemplates"]),
    ...mapActions("TKLogView", ["refreshScroll"]),
    setCurrentFilters,
    setTKStatus(
      this: {
        user: { authorities: { authority: authorities }[] };
        currentFilters: Record<string, unknown>;
        types: Record<string, string>[];
        refresh: () => void;
      },
      value: string
    ) {
      if (
        !value.length &&
        ![authorities.TK_DICTIONARIES_LIST, authorities.TK_OTHER_LIST, authorities.TK_DOCUMENTS_LIST].every((item) =>
          isAuthorityExist(this.user, item)
        )
      ) {
        this.currentFilters[fields.TK_TYPE] = [this.types[0].value];
      } else {
        this.currentFilters[fields.TK_TYPE] = value ? [value] : [];
      }

      this.refresh();
    },
    clearFiltersCustomCb(this: {
      clearFilters: () => void;
      restoreFiltersFromStore: (is: boolean) => void;
      refreshScroll: () => void;
      filtersDivHeight: number;
      defaultFiltersDivHeight: number;
      currentFilters: Record<string, unknown>;
      addFilter: (currentFilters: Record<string, unknown>) => void;
      setTKStatus: (value: string) => void;
    }) {
      this.clearFilters();
      this.filtersDivHeight = this.defaultFiltersDivHeight;
      this.restoreFiltersFromStore(true);
      this.setTKStatus("");
      this.addFilter(this.currentFilters);
      this.refreshScroll();
    },
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref(setCurrentFilters());
    const items = ref([] as IAutocompleteElement[]);
    const isLoading = ref(false);
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    useTemplateFilters(root, props.sectionName, currentFilters);
    const {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      restoreFiltersFromStore,
    } = useFilter(
      context,
      props as {
        value: boolean;
      },
      props.sectionName,
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements,
      notHiddenKeys,
      isShowToggle
    );
    const { getItems } = useOikAutocomplete(root, props.sectionName, currentFilters, items, isLoading);

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      restoreFiltersFromStore,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      items,
      isLoading,
      getItems,
    };
  },
});
